<template>
    <!-- 影像服务路由 -->
    <div class="photograph_box">
        <banner-carousel></banner-carousel>
        <!-- <photograph-banner></photograph-banner> -->
        <div class="photograph_list_box">
            <div class="container">
                <photograph-phototheme></photograph-phototheme>
                <!-- <photograph-bottom 
                    :title="videothemetitle"
                    :goods="videoTheme"></photograph-bottom> -->
                <!-- <photograph-bottom 
                    :title="nostalgictitle"
                    :goods="nostalgicTheme"></photograph-bottom> -->
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
    data(){
        return {
            // 视频主题标题 & 副标题
            videothemetitle:{
                title:"视频主题",
                subtitle:"一应俱全，只要你想要什么都能拍",
            },
            // 视频主题标题 & 副标题
            nostalgictitle:{
                title:"个人怀旧照相馆",
                subtitle:"那些已褪色的老照片，哪张勾起了你的回忆",
            },
        }
    },
    computed: mapState({
        videoTheme: state => state.goodsList.video_theme,
        nostalgicTheme: state => state.goodsList.nostalgic_theme,
    }),
    components:{
        bannerCarousel: () => import('@/components/bannerCarousel'),
        photographPhototheme: () => import('@/components/photographPhototheme'),
        photographBottom: () => import('@/components/photographBottom'),
    },
    methods: {
        ...mapMutations([
            "clearPhotograph",
        ]),
        ...mapActions([
            "getPhotograph",
            "getPhotographBanner",
        ]),
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            vm.getPhotograph();
            vm.getPhotographBanner();
        })
    },
    beforeRouteLeave (to, from, next) {
        this.clearPhotograph();
        next();
    }
}
</script>

<style>
/* element ui el-card */
.photograph_box .el-card.is-hover-shadow:hover{
    box-shadow:5px 5px 5px 0 rgba(0,0,0,.3);
}
.photograph_box{
    /* background-image: url('../assets/images/bg.jpg'); */
    background-color: #fafafa;
    background-size: cover;
}
/* 预约拍照内容区域 */
.photograph_list_box{
    padding-bottom: 80px;
}
</style>
